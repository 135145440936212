import React from "react"
import { Helmet } from "react-helmet"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Button from "../../components/button"
import PublicLayout from "../../components/layouts/PublicLayout"

const styles = theme => ({
  root: {
    backgroundColor: "white",
    maxWidth: '600px',
    margin: '0 auto',

    '& p': {
      marginBottom: '1.2rem'
    }
  },
  separator: {
    width: 60,
    height: 4,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4,
    margin: "1.5rem 0",
  },
  featureImg: {
    display: "block",
    marginBottom: 0,
  },
})

const RealEstatePage = props => {
  const { classes } = props

  return (
    <PublicLayout
      headline={"It just takes an idea"}
      subheading={
        "Have you been told you have great ideas?"
      }
      canonicalUrl="https://launchplan.com/ads/an-idea"
      showFreeTrial={false}
    >
      <Helmet>
        <title>Business Plan Software To Launch Your Idea | LaunchPlan</title>
        <meta
          name="description"
          content="Write business plans for real estate agents, real estate investing and development companies, and rental property firms using LaunchPlan  software. "
        />
        <meta
          property="og:title"
          content="Business Plan Software To Launch Your Idea | LaunchPlan"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://launchplan.com/ads/an-idea" />
        <meta
          property="og:image"
          content="https://launchplan.com/launchplan-logo-vertical.png"
        />

        {/* <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="206" />
          <meta property="og:image:height" content="112" /> */}
        <meta
          property="og:image:alt"
          content="The LaunchPlan logo, a rocket launching"
        />
      </Helmet>
      <section className={classes.root}>
        <Typography>
          Many great ideas can turn into great businesses! LaunchPlan can help you get your ideas down on a professional business plan which is the first step of starting your own business. Don't leave your idea sitting on the shelf, time to dust it off and build it out.
        </Typography>
        <Typography>
          According to sba.gov; “A good business plan guides you through each stage of starting and managing your business. You’ll use your business plan like a GPS for how to structure, run, and grow your new business. It’s a way to think through and detail all the key elements of how your business will run”.
        </Typography>
        <div className={classes.separator}></div>
        <Typography>
          Stop being told how good your ideas are and starting showing people what they’re about.
        </Typography>
        <Button variant="primary" href={`${process.env.GATSBY_APP_HOST}/signup?selection=FREE_ACCOUNT`}>
          {"Sign up for free"}
        </Button>
      </section>
    </PublicLayout>
  )
}

export default withStyles(styles)(RealEstatePage)

